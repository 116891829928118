import React from "react"
import PropTypes from "prop-types"
import ScrollUpButton from "react-scroll-up-button"
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import "./layout.scss"

const Layout = ({ children }) => {

	return (
		<>

		{children}

		<ScrollUpButton
			ContainerClassName="tz-back2top"
			TransitionClassName="tz-back2top--visible"
			ShowAtPosition={200}
		>
			<ExpandLessIcon></ExpandLessIcon>
		</ScrollUpButton>

		</>
	)

}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout