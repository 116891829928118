import React from "react"

import "./footer.scss"
import "../../pages/index.scss"

import ImgLogo from "../../images/logo-tanecni-zlonin.svg"

const Footer = () => (

	<div className="tz-footer">
		<div className="container">
			<h2 className="tz-footer__h2" data-sal="fade" data-sal-duration="1000">Naučíme vás tancovat.<br />Ve Zloníně.</h2>
			<div className="tz-footer__bottom">
				<div className="tz-footer__column tz-footer__column--first" data-sal="fade" data-sal-duration="1000">
					<img src={ImgLogo} alt="Taneční Zlonín logo" width="70" height="70" />
				</div>
				<div className="tz-footer__column tz-footer__column--second" data-sal="fade" data-sal-duration="1000">
					<h3 className="tz-footer__h3">Odkazy</h3>
					<ul className="tz-footer__links">
						<li>
							<a href="https://skzlonin.cz" target="_blank" rel="noreferrer" className="highlight tz-footer__link">SK Zlonín</a>
						</li>
						<li>
							<a href="https://www.zlonin.cz" target="_blank" rel="noreferrer" className="highlight tz-footer__link">Obec Zlonín</a>
						</li>
						<li>
							<a href="http://www.sut.cz" target="_blank" rel="noreferrer" className="highlight tz-footer__link">Svaz Učitelů Tance</a>
						</li>
						<li>
							<a href="https://zloninskydvur.cz" target="_blank" rel="noreferrer" className="highlight tz-footer__link">Zlonínský Dvůr</a>
						</li>
					</ul>
				</div>
				<div className="tz-footer__column tz-footer__column--third" data-sal="fade" data-sal-duration="1000">
					<h3 className="tz-footer__h3">Fakturační údaje</h3>
					<p>
						<strong>Taneční Zlonín z.s.</strong><br />
						IČO: 11964570<br />
						U Golfu 145/4<br />
						250 64&nbsp;&nbsp;Zlonín
					</p>
					<p>
						číslo účtu: 25071681/5500
					</p>
				</div>
			</div>
		</div>
	</div>

)

export default Footer