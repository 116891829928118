/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import ShareImage from "../../static/share-image.jpg"

function Seo({ description, lang, meta, title }) {
	const { site } = useStaticQuery(
		graphql`
		query {
			site {
			siteMetadata {
				title
				description
				keywords
			}
			}
		}
		`
	)

	const metaDescription = description || site.siteMetadata.description

	return (
		<Helmet
		htmlAttributes={{
			lang,
		}}
		title={title}
		titleTemplate={`%s | ${site.siteMetadata.title}`}
		meta={[
			{
				name: `image`,
				property: `og:image`,
				content: ShareImage,
			},
			{
				name: `description`,
				content: metaDescription,
			},
			{
				name: `keywords`,
				content: site.siteMetadata.keywords.join(","),
			},
			{
				property: `og:title`,
				content: title,
			},
			{
				property: `og:description`,
				content: metaDescription,
			},
			{
				property: `og:type`,
				content: `website`,
			},
			{
				name: `twitter:card`,
				content: `summary`,
			},
			{
				name: `twitter:creator`,
				content: site.siteMetadata.author,
			},
			{
				name: `twitter:title`,
				content: title,
			},
			{
				name: `twitter:description`,
				content: metaDescription,
			},
		].concat(meta)}
		/>
	)
}

Seo.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
}

Seo.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
}

export default Seo